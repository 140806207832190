import styled from 'styled-components';
import backgroundImage from '../../../assets/images/13-faq/background.svg';

export const Section13 = styled.div`
  background: #F7F7F7;
  padding: 70px 0 0 0;
  position: relative;
  .title{
    margin-bottom: 40px;
    strong{
      font-size: 45px;
      line-height: 145%;
    }
    span{
      font-size: 22px;
      font-weight: bold;
    }
    img{
      width: 89.9px;
      height: 69.16px;
    }
  }
  &:after{
      content: "";
      width: 663px;
      height: 622.65px;
      position: absolute;
      right: -35px;
      top: -50px;
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }
    @media (max-width: 700px) {
      &:after, &:before{
        display: none;
      }
    }
  }
`;

export const FAQ = styled.div`
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
  .question{
    background: #E7E7E7;
    border-radius: 18px;
    width: 100%;
    padding: 25px;
    span{
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .answer{
    margin-bottom: 35px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    &.open{
      margin-top: 15px;
    }
  }
`;
