import {
  Col, Container, Row,
} from 'react-bootstrap';
import Award from '../../../assets/images/17-o-que-faz/award.svg';
import Bolt from '../../../assets/images/17-o-que-faz/bolt.svg';
import Users from '../../../assets/images/17-o-que-faz/users.svg';
import * as S from './styles';

export default function OQueFaz() {
  return (
    <S.Section17>
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className="f-8">
              O QUE FAZ?
            </h3>
            <p className="mt-6 pt-2">
              <span className="color-green">Projetando experiências móveis significativas! Ferramenta robusta e escalável impactando de forma automática e humanizada com usabilidade mais simples possível.</span>
              <br />
              <br />
              Quando se trata de criar jornadas e comunicações de negócios para o cliente atual, a rapidez e a consistência são fundamentais. Vivemos numa época em que podemos alcançar qualquer pessoa ou qualquer coisa com o toque de um botão. É fundamental para as empresas adaptarem suas estratégias digitais para garantir que enviarão as informações certas, para a pessoa certa, no formato certo e na hora certa para garantir o resultado certo.
              {' '}
              <strong className="text-uppercase">Portanto a empresa tem que estar preparada:</strong>
            </p>
          </Col>
          <Col lg={4}>
            <div className="box mb-6 mb-lg-0">
              <img src={Award} alt="Antecipe a demanda do cliente" />
              <div>
                <p className="title f-5 font-weight-bold">Antecipe a demanda do cliente</p>
                <p className="mb-0 f-3">Seja proativo no atendimento, encante Logo no primeiro contato e saia na frente da concorrência.</p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="box mb-6 mb-lg-0">
              <img src={Bolt} alt="Autoatendimento" />
              <div>
                <p className="title f-5 font-weight-bold">Autoatendimento</p>
                <p className="mb-0 f-3">Ganhe autonomia e velocidade Deixe seu cliente à vontade para resolver os problemas de forma fácil e rápida através do autoatendimento, sem fila de espera.</p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="box">
              <img src={Users} alt="Humano" />
              <div>
                <p className="title f-5 font-weight-bold">Humano</p>
                <p className="mb-0 f-3">Resolva problemas complexos com eficiência, direcione para os agentes humanos os atendimentos consultivos e de alta complexidade e potencialize seus negócios.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </S.Section17>
  );
}
