import styled from 'styled-components';
import backgroundImage from '../../../assets/images/14-voce-acha/image.svg';

export const Section14 = styled.div`
    padding: 104px 0 75px 0;
    .anchor-image{
      position: relative;
      &:after{
        content: "";
        width: 306px;
        height: 389px;
        position: absolute;
        right: 0%;
        bottom: -103px;
        background-image: url(${backgroundImage});
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }
    @media (max-width: 700px) {
        &:after, &:before{
          display: none;
        }
      }
    }
    @media (max-width: 700px) {
      img{
        margin-bottom: -100px;
      }
    }
`;
