import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import * as S from './styles';
import backgroundImage from '../../../assets/images/14-voce-acha/image.svg';

export default function VoceAcha() {
  return (
    <S.Section14>
      <Container>
        <Row className="anchor-image">
          <Col>
            <h4 className="font-weight-light f-8">
              Você acha que grandes empresas fazem
              <br className="d-none d-lg-block" />
              {' '}
              <strong className="font-weight-bold f-8">gerenciamento de forma automática</strong>
              ?
            </h4>
            <p>
              Acredito que você acha que sim, pois Imagine que se não fosse assim, não conseguiram saber o que acontece
              <br className="d-none d-lg-block" />

              {' '}
              e a forma que acontecem as coisas em suas empresas, sejam elas atendimento, vendas, fidelização e muito
              <br className="d-none d-lg-block" />

              {' '}
              mais.
              {' '}
              <strong>
                Veja o caso da AMBEV e o processo de automação com o aplicativo Zé Delivery , um passo acertado
                <br className="d-none d-lg-block" />

                {' '}
                na comunicação com seus clientes.
              </strong>
            </p>
            <p className="color-blue f-7">
              Azutomatize, traz o método LOW CAC para ajudar com este processo,
              <br className="d-none d-lg-block" />

              {' '}
              comunicação personalizada de forma fácil e simples de implementar.
            </p>
            <p className="mb-0">
              Vender todos os dias é como oxigênio, precisamos respirar todos os dias. Atender seus clientes de forma
              <br className="d-none d-lg-block" />

              {' '}
              eficiente e tão importante como se alimentar.
            </p>
            <img className="d-block d-lg-none mt-5" src={backgroundImage} alt="Você acha que grandes empresas fazem gerenciamento de forma automática?" />
          </Col>
        </Row>
      </Container>
    </S.Section14>
  );
}
