import LazyLoad from 'react-lazyload';
import Ads from './Ads';
import AZTM from './AZTM';
import Bonus from './Bonus';
import ComoRecuperar from './ComoRecuperar';
import DeOndeSurgiu from './DeOndeSurgiu';
import Faq from './Faq';
import Indicadores from './Indicadores';
import Metodo from './Metodo';
import OQueFaz from './OQueFaz';
import Planos from './Planos';
import PorqueAzutomatize from './PorqueAzutomatize';
import QualAVantagem from './QualAVantagem';
import QuemSeDestina from './QuemSeDestina';
import Seguranca from './Seguranca';
import Solucoes from './Solucoes';
import Vantagens from './Vantagens';
import VenderMais from './VenderMais';
import Video from './Video';
import VoceAcha from './VoceAcha';

export default function Home() {
  return (
    <>
      <section id="VenderMais"><VenderMais /></section>
      <section id="Video"><Video /></section>
      <section id="Planos"><Planos /></section>
      <section id="Vantagens"><Vantagens /></section>
      <LazyLoad offset={500} once>
        <section id="Solucoes"><Solucoes /></section>
      </LazyLoad>
      <section id="QuemSeDestina"><QuemSeDestina /></section>
      <section id="ComoRecuperar"><ComoRecuperar /></section>
      <section id="PorqueAzutomatize"><PorqueAzutomatize /></section>
      <section id="Metodo"><Metodo /></section>
      <section id="AZTM"><AZTM /></section>
      <section id="Indicadores"><Indicadores /></section>
      <section id="Ads"><Ads /></section>
      <section id="Faq"><Faq /></section>
      <section id="VoceAcha"><VoceAcha /></section>
      <LazyLoad offset={500} once>
        <section id="DeOndeSurgiu"><DeOndeSurgiu /></section>
      </LazyLoad>
      <section id="Bonus"><Bonus /></section>
      <section id="OQueFaz"><OQueFaz /></section>
      <section id="QualAVantagem"><QualAVantagem /></section>
      <section id="Seguranca"><Seguranca /></section>
    </>
  );
}
