import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/2-video/video.svg';

export const Section17 = styled.div`
  margin-top: 100px;
  h3{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 173px;
      height: 9px;
      background: #FF9700;
      border-radius: 14px;
      left: 0;
      bottom: -20px;
    }
  }
  .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img{
      width: 50px;
    }
    width: 100%;
    background: #EFEFEF;
    box-shadow: 0px 60px 80px rgba(0, 0, 0, 0.07), 0px 35.328px 41.728px rgba(0, 0, 0, 0.050624), 0px 18.144px 19.584px rgba(0, 0, 0, 0.039312), 0px 7.296px 8.576px rgba(0, 0, 0, 0.030688), 0px 1.632px 3.712px rgba(0, 0, 0, 0.019376);
    border-radius: 30px;
    padding: 28px;
    height: 262px;
    .title{
      margin-bottom: 20px;
    }
  }
`;
