import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/9-metodo/background.svg';

export const Section9 = styled.div`
  background-color: #F7F7F7;
  padding: 65px 0;
  p, span, h3, h4, strong, div{
    position: relative;
    z-index: 2;
  }
  .f-30{
    font-size: 30px;
  }
  .circle{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border:2px solid ${({ theme }) => theme.colors.orange};
    font-weight: bold;
  }
  .lineh-control{
    line-height: 190%;
  }
  .line-height-negative{
    line-height: 180%;
  }
  .tags{
    display: flex;
    flex-wrap: wrap;
    span{
      background: #EEEEEE;
      border: 1px solid #D7D7D7;
      border-radius: 12px;
      padding: 8px 22px;
      color: ${({ theme }) => theme.colors.orange};
      font-weight: bold;
      width: inherit;
      margin-right: 20px;
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
  .backgroundobj{
    &:after{
    content: "";
    width: 1373px;
    height: 1221.52px;
    position: absolute;
    left: 48%;
    bottom: 40px;
    background-image: url(${backgroundImage});
    z-index: 1;
  }
  @media (max-width: 700px) {
    &:after{
      display: none;
    }
  }
  }
  @media (max-width: 700px) {
    .celular-mobile{
      width: 100%;
      margin: 100px auto 50px;
      display: block;
    }
  }
`;
