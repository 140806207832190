import {
  Col, Container, Row,
} from 'react-bootstrap';
import Contato from '../../../assets/images/11-indicadores/contato.svg';
import Email from '../../../assets/images/11-indicadores/email.svg';
import Grafico from '../../../assets/images/11-indicadores/graficos.svg';
import Lampada from '../../../assets/images/11-indicadores/lampada.svg';
import LogoMini from '../../../assets/images/11-indicadores/logo-mini.svg';
import Mensagem from '../../../assets/images/11-indicadores/mensagem.svg';
import Telefone from '../../../assets/images/11-indicadores/telefone.svg';
import Torpedo from '../../../assets/images/11-indicadores/torpedo.svg';
import Whatsapp from '../../../assets/images/11-indicadores/whatsapp.svg';

import * as S from './styles';

export default function Indicadores() {
  return (
    <S.Section11>
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className="f-8 color-white">
              Indicadores Componentes
              <br />
              {' '}
              da Ferramenta
            </h3>
            <p className="color-white-light f-7">
              Azutomatize é uma plataforma on-line que permite às empresas automatizar programaticamente
              {' '}
              <strong className="color-white f-7">serviços de voz, vídeo, chat e mensagens</strong>
              {' '}
              em seus aplicativos de negócios ou de consumo:
            </p>
          </Col>
        </Row>
        <Row className="d-none d-lg-flex">
          <Col lg={3}>
            <S.Box className="mb-0 mb-lg-5">
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={LogoMini} alt="1" />
                <span className="f-7 font-weight-bold ">
                  Azuphone
                </span>
              </p>
              <p className="f-3">
                A melhor solução PABX em nuvem empresarial. Levando a comunicação unificada para o seu negócio.
                <br />
                <br />
                <strong>Benefícios:</strong>
                <br />
                Seu Ramal em Qualquer Lugar Tenha ramais instalados em locais e até cidades e países diferentes. Integrado a Plataforma Azutomatize Esqueça o cabeamentos e suporte!
              </p>
            </S.Box>

            <S.Box2 className="mt-6 d-none d-lg-block">
              <p className="d-flex flex-column">
                <img src={Lampada} alt="1" className="mb-2" />
                <span>
                  <strong>Comunicação e gestão em um único local.</strong>
                  {' '}
                  Falando com seu cliente onde ele se sente mais confortável.
                </span>
              </p>
              <p className="d-flex flex-column">
                <img src={Grafico} alt="1" className="mb-2" />
                <span>
                  <strong>Ferramenta robusta e escalável</strong>
                  {' '}
                  impactando de forma automática e humanizada com usabilidade mais simples possível.
                </span>
              </p>
            </S.Box2>
          </Col>

          <Col lg={3}>
            <S.Box className="mb-0 mb-lg-5">
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Telefone} alt="1" />
                <span className="f-7 font-weight-bold ">
                  WebRTC
                  {' '}
                  <br />
                  Framework
                </span>
              </p>
              <p className="f-3">
                Revolucionário, que possibilita aplicações em tempo real, diretamente no browser de internet. Redução de custos em telefonia Reduza de 30% a 40% dos custos em telefonia.
              </p>
            </S.Box>
            <S.Box>
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Torpedo} alt="1" />
                <span className="f-7 font-weight-bold ">
                  Torpedo
                  <br />
                  de Voz
                </span>
              </p>
              <p className="f-3">
                Para recuperação de ativos, como cobranças, são realizados de forma mista, implementando campanhas automáticas com atendimento humanizado. O sucesso é comprovado vide &apos;&apos;callcenter`s&apos;&apos; de cobrança, pois comprovadamente convertem 80% a mais do que os atendimentos só automáticos. E os aliados para se comunicarem de forma simultânea e em conjunto com seus clientes, além do e-mail, SMS e o próprio Telefone. Forma comprovada e mais acertada, pois clientes não inadimplentes não gostam de interagir com cobranças.
              </p>
            </S.Box>
          </Col>

          <Col lg={3}>
            <S.Box className="mb-0 mb-lg-5">
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Email} alt="1" />
                <span className="f-7 font-weight-bold ">
                  E-mail
                  <br />
                  Marketing
                </span>
              </p>
              <p className="f-3">
                Alcance seus consumidores usando o Azutomatize, uma API de e-mail flexível que permite economia de tempo, escalabilidade e expertise com a entrega. Tudo de forma automática e agendada, levando seu cliente a realizar a ação desejada.
              </p>
            </S.Box>

            <S.Box>
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Contato} alt="1" />
                <span className="f-7 font-weight-bold ">
                  Contact
                  {' '}
                  <br />
                  Center
                  <br />
                  {' '}
                  Customizável
                </span>
              </p>
              <p className="f-3">
                Usando torpedo de voz para seus clientes, com mensagens de boas-vindas, novidades, compromissos financeiros, pesquisas e muito mais. Levando seu cliente a interagir com a unidade de atendimento. Totalmente personalizável com gravações profissionais ou do próprio colaborador, utilize cada aspecto do seu Contact Center e direcione os canais para um atendimento omnichannel humanizado.
              </p>
            </S.Box>
          </Col>
          <Col lg={3}>
            <S.Box className="mb-0 mb-lg-5">
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Whatsapp} alt="1" />
                <span className="f-7 font-weight-bold ">
                  WhatsApp
                  <br />
                  Business
                </span>
              </p>
              <p className="f-3">
                Converse, envie notificações e faça atendimento ao cliente no aplicativo de mensagens mais popular do mundo com a APIs de WhatsApp Conectados ao Azutomatize.
              </p>
            </S.Box>

            <S.Box>
              <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                <img src={Mensagem} alt="1" />
                <span className="f-7 font-weight-bold ">
                  Mensageria
                  {' '}
                  <br />
                  SMS
                </span>
              </p>
              <p className="f-3">
                Envie e receba mensagens em escala e com a confiança de entrega para mais de 180 países com as nossas APIs de mensagem como SMS, Uma campanha de SMS ativa a sua base de clientes e desperta a lembrança e a vontade do cliente comprar novamente do mesmo lugar, até mesmo porque ele sempre compra!
              </p>
            </S.Box>
          </Col>
        </Row>

        <div className="scroll-mobal d-block d-lg-none">
          <div>
            <div>
              <S.Box className="mb-0 mb-lg-5">
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={LogoMini} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    Azuphone
                  </span>
                </p>
                <p className="f-3">
                  A melhor solução PABX em nuvem empresarial. Levando a comunicação unificada para o seu negócio.
                  <br />
                  <br />
                  <strong>Benefícios:</strong>
                  <br />
                  Seu Ramal em Qualquer Lugar Tenha ramais instalados em locais e até cidades e países diferentes. Integrado a Plataforma Azutomatize Esqueça o cabeamentos e suporte!
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box className="mb-0 mb-lg-5">
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Telefone} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    WebRTC
                    {' '}
                    <br />
                    Framework
                  </span>
                </p>
                <p className="f-3">
                  Revolucionário, que possibilita aplicações em tempo real, diretamente no browser de internet. Redução de custos em telefonia Reduza de 30% a 40% dos custos em telefonia.
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box>
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Torpedo} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    Torpedo
                    <br />
                    de Voz
                  </span>
                </p>
                <p className="f-3">
                  Para recuperação de ativos, como cobranças, são realizados de forma mista, implementando campanhas automáticas com atendimento humanizado. O sucesso é comprovado vide &apos;&apos;callcenter`s&apos;&apos; de cobrança, pois comprovadamente convertem 80% a mais do que os atendimentos só automáticos. E os aliados para se comunicarem de forma simultânea e em conjunto com seus clientes, além do e-mail, SMS e o próprio Telefone. Forma comprovada e mais acertada, pois clientes não inadimplentes não gostam de interagir com cobranças.
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box className="mb-0 mb-lg-5">
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Email} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    E-mail
                    <br />
                    Marketing
                  </span>
                </p>
                <p className="f-3">
                  Alcance seus consumidores usando o Azutomatize, uma API de e-mail flexível que permite economia de tempo, escalabilidade e expertise com a entrega. Tudo de forma automática e agendada, levando seu cliente a realizar a ação desejada.
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box>
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Contato} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    Contact
                    {' '}
                    <br />
                    Center
                    <br />
                    {' '}
                    Customizável
                  </span>
                </p>
                <p className="f-3">
                  Usando torpedo de voz para seus clientes, com mensagens de boas-vindas, novidades, compromissos financeiros, pesquisas e muito mais. Levando seu cliente a interagir com a unidade de atendimento. Totalmente personalizável com gravações profissionais ou do próprio colaborador, utilize cada aspecto do seu Contact Center e direcione os canais para um atendimento omnichannel humanizado.
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box className="mb-0 mb-lg-5">
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Whatsapp} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    WhatsApp
                    <br />
                    Business
                  </span>
                </p>
                <p className="f-3">
                  Converse, envie notificações e faça atendimento ao cliente no aplicativo de mensagens mais popular do mundo com a APIs de WhatsApp Conectados ao Azutomatize.
                </p>
              </S.Box>
            </div>
            <div>
              <S.Box>
                <p className="d-flex align-items-start justify-content-start flex-row mb-3 pb-1">
                  <img src={Mensagem} alt="1" />
                  <span className="f-7 font-weight-bold ">
                    Mensageria
                    {' '}
                    <br />
                    SMS
                  </span>
                </p>
                <p className="f-3">
                  Envie e receba mensagens em escala e com a confiança de entrega para mais de 180 países com as nossas APIs de mensagem como SMS, Uma campanha de SMS ativa a sua base de clientes e desperta a lembrança e a vontade do cliente comprar novamente do mesmo lugar, até mesmo porque ele sempre compra!
                </p>
              </S.Box>
            </div>
          </div>
        </div>

        <Row className="d-flex d-lg-none">
          <Col>
            <S.Box2 className="mt-6">
              <p className="d-flex flex-column">
                <img src={Lampada} alt="1" className="mb-2" />
                <span>
                  <strong>Comunicação e gestão em um único local.</strong>
                  {' '}
                  Falando com seu cliente onde ele se sente mais confortável.
                </span>
              </p>
              <p className="d-flex flex-column">
                <img src={Grafico} alt="1" className="mb-2" />
                <span>
                  <strong>Ferramenta robusta e escalável</strong>
                  {' '}
                  impactando de forma automática e humanizada com usabilidade mais simples possível.
                </span>
              </p>
            </S.Box2>
          </Col>
        </Row>
      </Container>

    </S.Section11>
  );
}
