/* eslint-disable import/no-extraneous-dependencies */
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import LazyLoad from 'react-lazyload';
import * as S from './styles';
import LogoAztm from '../../../assets/images/9-metodo/aztm.svg';
import Termometro from '../../../assets/images/9-metodo/termometro.json';
import TermometroMobile from '../../../assets/images/9-metodo/termometro-mobile.json';
import Image from '../../../assets/images/4-vantagens/image.json';
import Celular from '../../../assets/images/9-metodo/image-celular.png';
import Graficos from '../../../assets/images/icons/graficos.svg';

export default function Metodo() {
  return (
    <S.Section9>
      <Container>
        <Row>
          <Col lg={12} className="d-flex align-items-start align-items-lg-center justify-content-start flex-column flex-lg-row">
            <img src={LogoAztm} alt="Icon Aztm" className="mr-5 mb-5 mb-lg-0" />
            <h3 className="f-8 f-lg-9 mb-0">
              Método diferente de
              <br className="d-none d-lg-block" />
              {' '}
              tudo o que você já viu.
            </h3>
          </Col>
          <Col lg={12} className="mt-6 mb-2 mb-lg-6">
            <p>
              Conclua ações de forma mais rápida, prática, econômica, mais eficiente, menos desgastante e o melhor, saiba de quais canais seus clientes estão vindo. Azutomatize desenvolveu o método LOW CAC.
              <br />
              <br />
              <strong>Solução que funciona exatamente dessa maneira:</strong>
              <br />
              Ela permite que as empresas entrem em contato com clientes, pacientes, funcionários, usando o canal de comunicação preferencial de cada um e assegura que o contato possa continuar a conversa em qualquer canal desejado, quando seu cliente estiver disponível, tudo isso em um único local, utilizando o
              {' '}
              <span className="color-orange">Trackeamento AZTM ‘’Azutomatize Monitor’’</span>
              , exclusivo no método Low Cac, medindo a temperatura de seus clientes e proporcionando a mudança de fase de cada um deles.
              <br />
              <br />
              <strong>A CLASSIFICAÇÃO É MEDIDA EM 5 FASES / LISTAS LEADS:</strong>
            </p>
          </Col>
          <Col lg={12} className="d-flex align-items-center justify-content-center mb-6">
            <Player
              className="d-none d-lg-block"
              autoplay
              loop
              src={Termometro}
            />
            <Player
              className="d-block d-lg-none mb-4"
              autoplay
              loop
              src={TermometroMobile}
            />
            {/* <img src={Termometro} alt="termometro" /> */}
          </Col>
          <Col lg={12}>
            <h4 className="f-8">
              Mas afinal de contas, para que serve
              <br />
              o
              {' '}
              <span className="color-orange f-8">Trackeamento AZTM</span>
              {' '}
              e o que é?
            </h4>
            <p className="mb-6">
              Trackeamento AZTM, é um link rastreável, você vai aprender como utilizar os dados dos visitantes do seu site, e-mails, SMS e mídias sociais. Utilizando a forma correta, para otimizar suas campanhas e esforços de marketing, reduzindo custos e aumentando resultados.
            </p>
          </Col>

          <Col lg={12} className="backgroundobj">
            <h4 className="f-8">
              Como utilizar
              {' '}
              <span className="color-orange f-8">“Trackeamento AZTM”</span>
              ,
              {' '}
              <span className="color-orange f-8">
                ”Azutomatize
                <br />
                {' '}
                Monitor’’
              </span>
              {' '}
              e o
              {' '}
              <span className="color-orange f-8">
                “Método Low Cac”
              </span>
              ?
              <br />
            </h4>
            <p className="mb-6">
              A melhor forma de acompanhar a jornada de seus clientes é se diferenciar no marketing digital. Pois através dos links de trackeamento e do método Low Cac que são aplicados diretamente em suas campanhas, possibilita a captura dos eventos já pré determinados em suas ações, apresentando assim o comportamento do consumidor em suas campanhas de SMS, e-mail, torpedo de voz e muito mais.
              <br />
              <br />
              Ou seja, utilizando a técnica de trackeamento e o método Low Cac, você vai beneficiar o seu negócio no sentido de rastrear as ações de suas campanhas, no atendimento automático, humanizado e muito mais, simplificando de vez a interação e entendendo o comportamento do cliente. Dessa forma, você consegue identificar pontos de temperatura dos seus clientes, impactar o processo de vendas online, aumentando suas conversões e gastando menos.
              <br />
              <br />
              <strong>
                Quando se trata de trackeamento e método Low Cac, são 02 pontos independentes e que se complementam:
              </strong>
              <span className="color-blue">GERAÇÃO</span>
              {' '}
              e
              {' '}
              <span className="color-blue">MONITORAMENTO</span>
              , que detecta a origem e os parâmetros.
              <br />
              <br />
              <strong>Quais origens?</strong>
              <br />
              <br />
              Como o nome já diz, se trata de algo que se origina de uma determinada fonte. Quando você está usando Trackeamento Low Cac, uma origem é o local de onde seu visitante se originou e saiu da ponta A para o ponta B, de acordo com cada estratégia.
              <br />
              <br />
              <strong>Isso pode parecer um pouco vago, então explicando melhor...</strong>
              <br />
              <br />
              Geralmente, quando você vende fazendo anúncios através de uma plataforma, como o Facebook ou Google, você precisa entender de onde originou aquela visita, que &apos;&apos;eventualmente&apos;&apos; converteu-se em uma venda.
              <br />
              <br />
              Normalmente são anúncios diretos de conversão (venda), mas tem um problema, além de serem muito caros, você ainda não consegue escalar, pois eles dividem com outros anunciantes, limitando você e seu negócio. E pior ainda, você não entende a jornada total do cliente, pois o comportamento dos seus clientes fica de posse das citadas acima!
            </p>
          </Col>
          <Col lg={12}>
            <h4 className="f-8">
              <strong className="f-8">
                E como eu posso iniciar o processo do
                {' '}
                <span className="color-orange f-8">Método Low Cac</span>
                ?
              </strong>
              <br />
              <span className="f-8 font-weight-light">MUITO SIMPLES, APLICANDO OS PARÂMETROS</span>
            </h4>
            <div className="d-flex align-items-start justify-content-start">
              <div className="mr-2 circle">01</div>
              <p className="mt-2 mb-4">Você precisa ter lista de clientes em sua base, antigas ou novas;</p>
            </div>
            <div className="d-flex align-items-start justify-content-start">
              <div className="mr-2 circle">02</div>
              <p className="mt-2 mb-4">
                Criando novas (lista gelada e fria), utilizando as redes sociais. Pagando muito mais barato pois são leads de descobrimento e relacionamento:
                {' '}
                <br />
                <strong>Exemplo:</strong>
                {' '}
                Imagine que você vai anunciar, de forma a apresentar uma oportunidade e tratar de assuntos que você é especialista, criando assim relacionamento com seu futuro cliente. Os anúncios podem ser feitos através dos canais mencionados, como Facebook e o Google, só que desta vez por não se tratar de anúncio de venda direta, você pagará muito mais barato e construirá sua lista, com pessoas que se conectaram com seus temas e assuntos.
              </p>
            </div>
            <div className="d-flex align-items-start justify-content-start">
              <div className="mr-2 circle">03</div>
              <p className="mt-2 mb-6">A classificação de sua lista será gelada e fria, a partir daí começa o método!</p>
            </div>
            <p className="mb-6">
              A partir daí, você vai se comunicar com seus prospects e clientes de uma forma personalizada, utilizando o método Low Cac, nutrindo as pessoas na jornada da consciência , de seus produtos e serviços, mudando-os de temperatura até que um desses visitantes e ou clientes fiquem super quentes e como consequência realizam uma compra e se tornem fãs de sua marca.
              <br />
              <br />

              A comunicação se dá pela plataforma Azutomatize, por meio de mensagens, chat, e-mail, voz e vídeo. No entanto com um enorme diferencial, utilizará o seu próprio ‘’Trackeamento AZTM’’ maior diferencial do mercado.
              <br />
              <br />

              Mas quando se trata de usar trackeamento e o método Low Cac, precisamos ir um pouco além, já que isso foi apenas para você entender para que o trackeamento Low Cac serve.
              <br />
              <br />

              <strong>Afinal, o método Low Cac mostra através do Trackeamento AZTM, qual caminho percorrido até a venda concreta, com isso você vai refinar cada vez mais suas campanhas, escalando, vendendo muito e com alta margem de lucro.</strong>
              <br />
              <br />

              Enquanto o trackeamento tradicional através dos canais mencionados, como Facebook e Google, passa a ser uma técnica inútil e cara, pois eles não passam o comportamento em sua totalidade, não concorda?
            </p>
          </Col>
          <Col lg={7}>
            <p>
              <strong className="f-7">E É FATO!</strong>
              <br />
              O trackeamento pelas &apos;&apos;Big’s&apos;&apos;, de acordo com as mudanças que ocorreram
              <br className="d-none d-lg-block" />
              {' '}
              nos últimos anos, relacionadas ao iOS 14 da Apple, essas plataformas,
              <br className="d-none d-lg-block" />
              {' '}
              principalmente o Facebook e todo seu ecossistema, foram fortemente
              <br className="d-none d-lg-block" />
              {' '}
              impactadas, impedindo que você conseguisse entender comportamentos
              <br className="d-none d-lg-block" />
              {' '}
              mais específicos do usuário. E atualmente quando você está testando
              <br className="d-none d-lg-block" />
              {' '}
              diferentes tipos de anúncios (imagens, vídeos, carrosséis, Stories, feed, reels,
              <br className="d-none d-lg-block" />
              {' '}
              pesquisa etc.), muitas vezes você não consegue saber com exatidão o
              <br className="d-none d-lg-block" />
              {' '}
              comportamento do usuário e a origem desses diferentes anúncios e
              <br className="d-none d-lg-block" />
              {' '}
              posicionamentos!
            </p>
            <p className="d-none d-lg-block lineh-control">
              <strong className="f-30">E no que isso impacta?</strong>
              <br />
              <span className="f-30 font-weight-light">É SIMPLES!</span>
            </p>
          </Col>
          <Col lg={5} className="mt-5 mt-lg-0">
            <LazyLoad offset={500} once>
              <img src={Celular} alt="Celular" className="mt-n5 celular-mobile" />
            </LazyLoad>
          </Col>
          <Col lg={12}>
            <p className="d-block d-lg-none ">
              <strong className="f-30">E no que isso impacta?</strong>
              <br />
              <span className="f-30 font-weight-light">É SIMPLES!</span>
            </p>
            <p className="mt-n3">
              <span className="color-blue f-7  line-height-negative">Quais dos seus anúncios no Facebook</span>
              <br />
              <span className="color-blue f-7">estão tendo melhor performance?</span>
              {' '}
              <br />
              <strong>Quais canais, ou posicionamentos, dentro de cada uma dessas plataformas você está conseguindo atingir seus objetivos com o menor custo?</strong>
              <br />
              <br />
              Além disso, as mudanças que ocorreram nos dispositivos relacionados à leitura de dados, que inclusive em cada plataforma é feita de forma distinta, às vezes você não consegue conciliar os dados de visita porque em uma plataforma exibe uma coisa, e na outra plataforma exibe outra.
            </p>
            <p>
              <span className="color-blue f-7">Mas afinal, qual delas está correta?</span>
              <br />
              <strong>E principalmente: como resolver esses problemas?</strong>
              <br />
              <br />
              E é aí, que entra a magia do Azutomatize com método Low Cac! Utilizamos diferentes tipos de origens, definidas universalmente para identificação das visitas e comportamentos de fluxo de usuários e sua temperatura utilizando o Trackeamento AZTM. Atualmente, utilizamos as melhores, pois conseguimos atingir praticamente todas a pessoas que tem um dispositivo móvel e as formas de origens são: Utilização do Trackeamento AZTM, através do método Low Cac, faça campanhas utilizando:
            </p>
            <div className="tags">
              <span>SMS Trackeado</span>
              <span>E-mail Trackeado</span>
              <span>Torpedo de Voz Trackeado</span>
              <span>Ura Inteligente Trackeada em real time</span>
              <span>Campanhas personalizadas para chatBots</span>
              <span>Posicionamentos e Pesquisas ”NPS”</span>
            </div>
            <p className="mb-0">
              <img src={Graficos} className="mr-2" alt="Conteúdos, aulas e muito mais, dependendo da criatividade de cada estratégia." />
              <strong>CONTEÚDOS, AULAS E MUITO MAIS, DEPENDENDO DA CRIATIVIDADE DE CADA ESTRATÉGIA.</strong>
            </p>
          </Col>
        </Row>
      </Container>

    </S.Section9>
  );
}
