import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/2-video/video.svg';

export const Section16 = styled.div`
  .box-1{
    margin-top: -170px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 35.328px 41.728px rgba(0, 0, 0, 0.050624), 0px 7.296px 8.576px rgba(0, 0, 0, 0.030688), 0px 1.632px 3.712px rgba(0, 0, 0, 0.019376);
    border-radius: 25px;
    padding: 43px 53px 270px 53px;
    @media (max-width: 700px) {
      padding: 43px 20px 270px 20px;

    }
  }
  .box-2{
    background: #2F53E4;
    box-shadow: 0px 60px 80px rgba(0, 0, 0, 0.07), 0px 35.328px 41.728px rgba(0, 0, 0, 0.050624), 0px 18.144px 19.584px rgba(0, 0, 0, 0.039312), 0px 7.296px 8.576px rgba(0, 0, 0, 0.030688), 0px 1.632px 3.712px rgba(0, 0, 0, 0.019376);
    border-radius: 28px;
    margin: -270px 53px 0;
    padding: 37px 47px 0 47px;
    @media (max-width: 700px) {
      margin: -270px 20px 0;
      padding: 37px 20px 0 20px;
    }
    hr{
      border: 0px;
      height: 2px;
      background: #23FBD9;
      margin: 40px 0 35px;
    }
    .color-green2{
      color: #23FBD9;
      font-weight: bold;
    }
    .value{
      color: #fff;
      font-size: 32px;
      font-weight: bold;
      span{
        color: #fff;
        font-size: 18px;
      }
    }
  }
  @media (max-width: 700px) {
    .bonus-icon{
      width: 65.39px
    }
    .font-mobal{
        font-size: 28px !important;
      }
  }
`;
