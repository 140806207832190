import styled from 'styled-components';
import backgroundImage from '../../../assets/images/11-indicadores/background.svg';

export const Section11 = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: #2F53E4;
  padding: 55px 0 77px 0;
  position: relative;
  @media (max-width: 700px) {
    padding: 75px 0 27px 0;
  }
  &:after{
      content: "";
      width: 852.82px;
      height: 758.72px;
      position: absolute;
      left: 60%;
      bottom: 0px;
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }
    @media (max-width: 700px) {
      &:after{
        width: 279.57px;
        height: 248.72px;
        left: -62px;
        bottom: 384px;
        background-size: cover;
        }
      }
    @media (max-width: 700px) {
      .scroll-mobal{
        max-width: 400px;
        > div {
          width: 100%;
          overflow-x: scroll;
          display: flex;
          flex-direction: row;
          > div{
            margin-right: 16px;
          }
        }
      }
    }
`;

export const Box = styled.div`
  color: red;
  background: #2642B6;
  max-width: 271px;
  padding: 20px;
  border-radius: 30px;
  position: relative;
  z-index: 2;
  @media (max-width: 700px) {
    width: 271px !important;
    min-width: 271px !important;
  }
  p, span, strong{
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
  }
  img{
    margin-right: 10px;
  }
`;

export const Box2 = styled.div`
  max-width: 271px;
  position: relative;
  z-index: 2;
  @media (max-width: 700px) {
    max-width: 400px;
    width: 100% !important;
  }
  p, span{
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
  }
  strong{
    color: ${({ theme }) => theme.colors.white};
  }
  img{
    width: 40px;
  }
`;
