import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import FaqIcon from '../../../assets/images/13-faq/faq-icon.svg';

import * as S from './styles';
import FAQ from './faq';

export default function Faq() {
  return (
    <S.Section13>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="d-flex align-items-start align-items-lg-center justify-content-start flex-column flex-lg-row title">
              <img src={FaqIcon} alt="faq icon" className="mr-3 mb-3 mb-lg-0" />
              <span>
                <strong>FAQ</strong>
                <br />
                Perguntas frequentes
              </span>
            </div>
          </Col>
          <Col lg={12}>
            <FAQ
              question="QUAIS TIPOS DE MENSAGENS DA AZUTOMATIZE?"
              answer="
              <span class='color-blue'>•</span> SMS <br />
              <span class='color-blue'>•</span> WhatsApp<br />
              <span class='color-blue'>•</span> Facebook Messenger<br />
              <span class='color-blue'>•</span> Instagram<br />
              <span class='color-blue'>•</span> APIs de mensagens<br />
              <span class='color-blue'>•</span> E-mails
            "
            />
            <FAQ
              question="Como implementar na jornada do seu cliente e<br /> aumentando as conversões e reduzindo o churn?"
              answer="Utilizando o método Low Cac e parametrizando suas listas com temperaturas e cada fase da jornada de seus clientes."
            />
            <FAQ
              question="Há conectividade com Numero de Telefone - DID - (short) códigos curtos para SMS?"
              answer="Sim."
            />
            <FAQ
              question="Há números locais e internacionais?"
              answer="Sim, expanda sua presença global, adicione um número nacional ou internacional para chamadas inbound, ou outbould, chamada de duas  vias e assistência emergencial."
            />
            <FAQ
              question="PRECISO TER Números habilitados para envio de mensagens de texto?"
              answer="Envie mensagens SMS de qualquer número local
              "
            />
            <FAQ
              question="HÁ Suporte para o crescimento do seu negócio e aplicação do método?"
              answer="Sim,  lives  semanais via Zoom.
              "
            />
            <FAQ
              question="EXISTEM SERVIÇOS VERIFICAÇÃO?"
              answer="Sim, Verificação via SMS.<br />
              CASOS DE USO:<br />
              Fornecer Segurança e Confiança Aprimore a segurança do aplicativo e proteja os dados       compartilhados dos clientes com soluções de autenticação multifatorial escaláveis
              "
            />
            <FAQ
              question="HÁ Garantia DE alcance global?"
              answer="Use números de telefone como uma identidade de usuário global com métodos de verificação.<br />
              Melhore a aquisição aumente as taxas de conversão em seu fluxo de captura com verificação de número de telefone.
              "
            />
            <FAQ
              question="O QUE É Marketing Conversacional?"
              answer="
              Marketing Conversacional:<br />
Engaje os clientes com conversas significativas em tempo real;<br />
Atendimento ao Cliente Real Time;<br />
Crie o atendimento ao cliente com conversas que constroem relacionamentos;<br />
Automatize a jornada do cliente e forneça uma experiência unificada on line e automatizada.<br />
Utilizando os aplicativos disponíveis em nossa plataforma<br /><br />

Como usar SMS em campanhas de inbound Marketing?<br />
O SMS é uma das formas de comunicação mais onipresentes atualmente – portanto, não é surpresa que as campanhas de marketing por SMS estejam aumentando à medida que mais empresas percebem os benefícios de segmentar clientes por meio de seus canais preferidos.<br /><br />

O argumento para usar o SMS como um canal de marketing primário é forte. Para começar, é um canal confiável e disponível em todos os celulares, independentemente do provedor de rede, região ou sistema operacional. As notificações por SMS aparecem diretamente na tela do telefone e, em muitos casos, o destinatário pode ver de quem é a mensagem e entender sua intenção sem precisar desbloquear o telefone.<br /><br />

E se compararmos a eficácia do SMS com o e-mail, outro canal de marketing popular, a diferença é gritante. Há mais de um terço de um trilhão de e-mails enviados todos os dias em todo o mundo, mas muitos deles são filtrados em subpastas ou lixo eletrônico e nunca são lidos. As taxas de leitura de e-mail – mesmo os legítimos – ficam em torno de 20% e estão caindo todos os dias porque muitas pessoas não conseguem lidar com o grande volume que recebem.<br /><br />

Por outro lado, o SMS atinge taxas de abertura em torno de 95% e as respostas levam em média 90 segundos – 60 vezes mais rápido que o e-mail. Simplificando, as campanhas de marketing por SMS podem ser uma das táticas mais impactantes que uma empresa pode adotar. Mas, como em qualquer estratégia de marketing, a abordagem correta é fundamental.<br /><br />

Os pilares de uma campanha de marketing por SMS bem-sucedida<br />
Embora a abordagem exata varie dependendo da empresa e de seus clientes, existem algumas práticas recomendadas essenciais a serem seguidas para garantir que você aproveite ao máximo cada campanha de marketing por SMS:<br /><br />

Ser conciso
Por ter uma taxa de resposta tão rápida, o SMS tende a ser mais eficaz para campanhas em que você está tentando obter engajamento imediato. Para esse fim, uma das considerações mais importantes ao elaborar uma campanha de marketing por SMS é pensar em como capturar a atenção do destinatário rapidamente e usar menos palavras para transmitir sua mensagem.<br />

Mantenha suas mensagens simples e acionáveis e certifique-se de incorporar uma maneira de o destinatário se envolver diretamente com você. Isso pode ser feito por meio de um URL personalizado na mensagem que os leva a um site específico WhatsApp, telegram ou uma palavra-chave de entrada que o destinatário pode usar para acionar a próxima atividade.<br />

Considere SMS em todos os pontos de contato
Ao projetar suas jornadas de experiência do cliente, o SMS deve ser considerado em todos os pontos de contato de comunicação. Faça a si mesmo a pergunta: esta mensagem está agregando valor ao cliente neste ponto de contato e está conduzindo-o na jornada? Permitir que os clientes optem por receber comunicações por SMS nesses pontos de contato ajuda a garantir que você não os importune, mas fornece as informações que eles desejam.<br />

Não exagere
Se você mudar muito de sua comunicação com um cliente para SMS sem obter permissão de aceitação deles, você corre o risco de ser visto como spam e ter seu número bloqueado pelo destinatário.<br />
Os destinatários têm muito mais controle sobre quem pode enviar uma mensagem de texto – a maioria dos telefones permite que os usuários bloqueiem números de telefone – então você realmente precisa se certificar de que as comunicações que você está enviando via celular sejam vistas como valiosas e não como uma intrusão ou incômodo.<br />
Você pode criar campanhas de SMS marketing com branding, imagens, carrosséis e botões para realizar uma ação, como navegar para outros produtos ou confirmar um compromisso. Tudo isso pode ser feito sem a necessidade de mudar para outro aplicativo.
              "
            />
          </Col>
          <Col lg={12} className="text-center mt-6 mb-n2 d-none d-lg-block">
            <h5 className="f-8">Não tem mais nenhuma dúvida?</h5>
            <p>
              <strong>Clique no botão abaixo e crie de imediato as condições para mudar a vida de seus clientes</strong>
              , Ajuste seu negócio para a criação de experiências personalizadas. Venda em escala, com alta margem de lucro, construa com confiança e impulsione seus negócios, seja constante.
            </p>
            <Button placeholder="ASSINE AGORA" href="/#Planos" />
          </Col>
        </Row>
      </Container>
    </S.Section13>
  );
}
