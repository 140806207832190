/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import Bar from '../../../assets/images/13-faq/bar.svg';
import Plus from '../../../assets/images/13-faq/plus.svg';
import * as S from './styles';

function FAQ({ question, answer }) {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const answerRef = useRef(null);

  function toggleAnswerVisibility() {
    const answerElement = answerRef.current;

    if (answerElement) {
      answerElement.style.maxHeight = isAnswerVisible ? '0px' : `${answerElement.scrollHeight}px`;
    }

    setIsAnswerVisible(!isAnswerVisible);
  }

  return (
    <S.FAQ>
      <div
        className="question d-flex justify-content-between"
        onClick={toggleAnswerVisibility}
      >
        <span dangerouslySetInnerHTML={{ __html: question }} />
        {isAnswerVisible ? (
          <img src={Bar} alt="Close" />
        ) : (
          <img src={Plus} alt="Open" />
        )}
      </div>
      <div
        className={`answer ${isAnswerVisible ? 'open' : 'close'}`}
        ref={answerRef}
        dangerouslySetInnerHTML={{ __html: answer }}
      />

    </S.FAQ>
  );
}

export default FAQ;
