import '../../assets/styles/custom.scss';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/theme/default';
import Header from '../Header';
import Footer from '../Footer';
import Router from '../../Router';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <Header />
        <Router />
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}
export default App;
